import { Box } from '@chakra-ui/layout';
import { chakra, SimpleGrid } from '@chakra-ui/react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import CollectionItem from '../../components/CollectionItem';
import EmptyTab from '../../components/EmptyTab/index';
import TokenList from '../../components/TokenList/index';
import * as RoutePaths from '../../utils/constants/routings';
import useSearch from '../../utils/hooks/useSearch';

const SearchPage = () => {
  let history = useHistory();
  let location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState('');

  const onChange = () => {
    setQuery(queryString.parse(location.search).query);
    setPageNumber(1);
  };
  const { tokens, tokenCount, collections, collectionCount, hasMore, loading, error } = useSearch({
    query: queryString.parse(location.search).query,
    pageNumber,
    onChange,
  });
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleSortMenu = async (value) => {
    setQuery(value);
    setPageNumber(1);
  };

  const observer = React.useRef();
  const lastTokenElementRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <Box maxWidth="1536px" m={(0, 'auto')} pt={12} padding="80px 40px 121px 40px">
        <Box color="white" fontSize={36} fontWeight="bold" pb={8}>
          Search results for <chakra.span color="teal.200">{queryString.parse(location.search).query} </chakra.span>
        </Box>
        <Box>
          <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)} colorScheme="teal" color="teal.200">
            <TabList>
              <Tab>Nfts ({`${tokenCount}`})</Tab>
              <Tab>Collections ({`${collectionCount}`})</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box pt={{ base: 240, sm: 340, md: 240, lg: 120 }}>
                  <TokenList
                    tokens={tokens}
                    handleSort={handleSortMenu}
                    loading={loading}
                    setPageNumber={setPageNumber}
                    hasMore={hasMore}
                    error={error}
                    headerVisability={false}
                  />
                </Box>
              </TabPanel>
              <TabPanel>
                {collections.length > 0 ? (
                  <SimpleGrid columns={[1, 2, 3]} gridColumnGap={6} gridRowGap={3} pb="0">
                    {collections.map((collection, index) => {
                      if (collections.length === index + 1) {
                        return (
                          <div ref={lastTokenElementRef}>
                            <CollectionItem key={index} collection={collection} loading={loading}></CollectionItem>
                          </div>
                        );
                      } else {
                        return <CollectionItem key={index} collection={collection} loading={loading}></CollectionItem>;
                      }
                    })}
                    {/* {loading && <TokensSkeleton />} */}
                    {/* <div>{error && 'Error'}</div> */}
                  </SimpleGrid>
                ) : (
                  <EmptyTab
                    text={`Moon Rabbit finds your lack of NFTs disturbing.`}
                    buttonText="Browse marketplace"
                    onClick={() => history.push(RoutePaths.HOME)}
                  />
                )}
              </TabPanel>
              <TabPanel>
                <SimpleGrid columns={[1, 2, 3]} gridColumnGap={6} gridRowGap={3} pb="0">
                  {/* <CollectionItem collection={collection}></CollectionItem>
                  <CollectionItem collection={collection}></CollectionItem>
                  <CollectionItem collection={collection}></CollectionItem>
                  <CollectionItem collection={collection}></CollectionItem> */}
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default SearchPage;
