import React from 'react';
import './style.css';

const MarketplaceHeader = () => {
  return (
    <div className="banner">
      <div className="left-block">
        <div className="rabbit"></div>
      </div>
      <div className="center-block">
        <div className="title">
          <h1>
            Moon Rabbit
            <br /> NFT Marketplace
          </h1>
          <p>Your one-stop shop to enter the Metaverse!</p>
        </div>
      </div>

      <div className="right-list">
        {/* <div className="item">
          <CheckImg className="check" />
          Zero-Fee Minting & Trading
        </div>
        <div className="item">
          <CheckImg className="check" />
          Custom Royalty for Reselling
        </div>
        <div className="item">
          <CheckImg className="check" />
          One-Click NFT Generation
        </div>
        <div className="item">
          <CheckImg className="check" />
          AI-Generated & GameFi NFTs
        </div>
        <div className="item">
          <CheckImg className="check" />
          Exclusive Deals for $AAA Holders
        </div> */}
      </div>
    </div>
  );
};

export default MarketplaceHeader;
