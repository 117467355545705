import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import SortSrc from '../../assets/images/icons/sorting_black.png';

const HeaderTokenList = ({ title, data, handleSort }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text color="white" fontSize="32" fontWeight="bold">
        {title}
      </Text>
      <Menu closeOnSelect={true}>
        <MenuButton as={Button} colorScheme="teal" color="black" variant="with-shadow">
          <Flex alignItems="center">
            <Image w={4} h={4} src={SortSrc} color="black" mr={2}></Image> Sort & Filter
          </Flex>
        </MenuButton>
        <MenuList minWidth="240px">
          <MenuOptionGroup
            defaultValue="ALL"
            title="Sort & Filter"
            type="radio"
            onChange={(value) => handleSort(value)}
          >
            <MenuItemOption value="ALL">All Nfts</MenuItemOption>
            <MenuItemOption value="PRICE_ASC">Price: Low to High</MenuItemOption>
            <MenuItemOption value="PRICE_DESC">Price: High to Low</MenuItemOption>
            <MenuItemOption value="START_ASC">Recently started</MenuItemOption>
            <MenuItemOption value="ENDING_ASC">Auction ending soon</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default HeaderTokenList;
