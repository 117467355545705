import { Box } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from '../Footer/index';
// import BackgroundSrc from '../../assets/images/background_img.jpg';
import Header from '../Header';

const HomeLayout = ({ children }) => (
  <>
    <Box>
      <Header />
      <Box w="100%">{children}</Box>
      <Footer></Footer>
    </Box>
  </>
);

HomeLayout.propTypes = { children: PropTypes.node.isRequired };

export default HomeLayout;
